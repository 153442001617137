var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"cbQNpFAwxtxgdbdUGwAZt"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/static/hitch";

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN =
  'https://dfddf0aabbab4be4937d4082aad36bab@disney.my.sentry.io/73';
const TRACES_SAMPLE_RATE = 0.05;

Sentry.init({
  dsn: SENTRY_DSN,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: TRACES_SAMPLE_RATE,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  release: `hitch@${__APP_VERSION__}`,
});
